body {
  margin-bottom: 50px;
}

small {
  &.normal {
    font-weight: normal;
  }
}

form.pregled label {
  padding-top: 0 !important;
}

.text-red {
  padding-top:10px;
  color:red;
}